import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
  <li>
    <a href="http://schanelyphotography.com">
      <i className="fas fa-globe-americas fa-lg"></i>
      View site &raquo;
    </a>
  </li>
    </ul>
    <p>{`My darling bride launched her own photography business in April 2017. I built this site for her launch. It was a great opportunity to experiment with custom themes in WordPress. I also fine-tuned her hand-lettered logo for some promotional materials.`}</p>
    <p>{`One highlight for me was the work I did on the grid system for this site. I wanted to try breaking out of the 12-column system I use in so many of my projects and experiment with the golden ratio. From the base width of 1200 pixels, I placed lines from the left and right edges at the golden ratio dimension. I then subdivided each of these spaces further to get a balanced but somewhat asymmetrical 5-column system. I applied the same idea to determine some vertical rhythm up and down in the layout, particularly in the masthead.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/ks-photo-grid.png",
        "alt": null
      }}></img></p>
    <p>{`I fleshed out a few different combinations for column areas and measured each dimension. Then I built each out using a class. Flexbox made the final assembly a breeze.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/ks-photo-grid-sizes.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      